import React from "react";
import { PageWrapperDark } from "../components/page-wrapper";
import "../styles/pages/services.scss";

function Services() {
  return (
    <PageWrapperDark>
      <main>
        <ServiceBanner />
        <section className="services-section">
          <div className="container">
            <ul>
              <li>Underlayment</li>
              <li>Valley Metal</li>
              <li>Skylight Flashings</li>
              <li>Hip + Ridge Caps</li>
              <li>Fascia Cladding</li>
              <li>Dripedge</li>
              <li>Standing Seam</li>
              <li>Stone Clad Metal Roof</li>
              <li>Custom Gutters</li>
              <li>Torchdown</li>
              <li>Hot Mop System</li>
              <li>Decking System</li>
              <li>Roofing Debris Clean Up &amp; Haul Away</li>
              <li>Workmanship Warranty</li>
              <li>Manufacturer Material Warranty</li>
            </ul>
          </div>
        </section>
      </main>
    </PageWrapperDark>
  );
}

function ServiceBanner() {
  return (
    <section className="services-banner">
      <div className="container">
        <h1>
          Services<span>.</span>
        </h1>
      </div>
    </section>
  );
}

export default Services;
